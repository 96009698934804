import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaInfoCircle, FaShieldAlt, FaFileAlt, FaLock, FaTag, FaChartLine, FaDatabase, FaQuestionCircle, FaUserShield, FaUserLock, FaCreditCard, FaHandshake, FaUserGraduate, FaUserClock } from 'react-icons/fa';

// Import Roboto font
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  body {
    font-family: 'Roboto', sans-serif;
  }
`;

const FooterContainer = styled.footer`
  background-color: #ffffff;
  color: #333;
  padding: 3rem 2rem;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  padding: 1rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #322333;
    font-weight: 700;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: #333;
        margin-left: 0.5rem;
        transition: color 0.3s ease, transform 0.3s ease;
        font-weight: 400; /* Regular weight */

        &:hover {
          color: #00a76f;
          transform: translateX(5px);
        }
      }

      svg {
        color: #00a76f;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const Divider = styled.div`
  height: 2px;
  background-color: #dfe0e4;
  margin: 2rem 0;
  border-radius: 1px;
`;

const Copyright = styled.div`
  text-align: left;
  padding: 1rem 0;
  color: #555;
  font-weight: 400;

  @media (max-width: 768px) {
    padding: 1rem 0;
    text-align: center;
  }
`;

const Footer = () => {
  return (
    <>
      <GlobalStyle />
      <FooterContainer>
        <FooterContent>
          <FooterSection>
            <h3>Invisio Me</h3>
            <ul>
              <li><FaInfoCircle /><Link to="/about-us">About us</Link></li>
              <li><FaFileAlt /><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><FaFileAlt /><Link to="/terms-conditions">Terms & Conditions</Link></li>
              <li><FaShieldAlt /><Link to="/security">Security</Link></li>
            </ul>
          </FooterSection>
          <FooterSection>
            <h3>Service</h3>
            <ul>
              <li><FaTag /><Link to="/pricing">Pricing</Link></li>
              <li><FaChartLine /><Link to="/business">Business</Link></li>
              <li><FaDatabase /><Link to="/data-brokers">Data Brokers</Link></li>
              <li><FaQuestionCircle /><Link to="/faqs">FAQs</Link></li>
            </ul>
          </FooterSection>
          <FooterSection>
            <h3>Benefits</h3>
            <ul>
              <li><FaUserShield /><Link to="/data-privacy">Data Privacy</Link></li>
              <li><FaUserLock /><Link to="/scam-protection">Scam Protection</Link></li>
              <li><FaUserShield /><Link to="/identity-protection">Identity Protection</Link></li>
              <li><FaCreditCard /><Link to="/credit-protection">Credit Protection</Link></li>
            </ul>
          </FooterSection>
          <FooterSection>
            <h3>Programmes</h3>
            <ul>
              <li><FaHandshake /><Link to="/affiliate">Affiliate</Link></li>
              <li><FaUserGraduate /><Link to="/student-discount">Student Discount</Link></li>
              <li><FaUserClock /><Link to="/over-60s-discount">Over 60s Discount</Link></li>
            </ul>
          </FooterSection>
        </FooterContent>
        <Divider />
        <Copyright>
          &copy; {new Date().getFullYear()} Invisio Me. All rights reserved.
        </Copyright>
      </FooterContainer>
    </>
  );
};

export default Footer;
