import React from 'react';

function ToS() {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>Our terms and conditions...</p>
    </div>
  );
}

export default ToS;
