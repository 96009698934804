import React from 'react';

function HowItWorks() {
  return (
    <div>
      <h1>How It Works</h1>
      <p>Information about how the service works...</p>
    </div>
  );
}

export default HowItWorks;
