import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';

const breakpoints = {
  mobile: '768px',
};

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${breakpoints.mobile}) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    z-index: 1000;
    overflow-y: auto;
    justify-content: flex-start;
  }
`;

const NavLink = styled(Link)`
  padding: 0.5rem 0.5rem;
  color: ${({ isActive }) => (isActive ? '#00a76f' : '#333')};
  text-decoration: none;
  font-weight: normal;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '•';
    position: absolute;
    left: -2px;
    font-size: 1.5rem;
    line-height: 1;
    color: ${({ isActive }) => (isActive ? '#00a76f' : 'transparent')};
    transition: all 0.3s ease;

    @media (max-width: ${breakpoints.mobile}) {
      display: none;
    }
  }

  &:hover {
    color: #555;

    &::before {
      color: ${({ isActive }) => (isActive ? '#00a76f' : 'transparent')};
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const NavButton = styled(Link)`
  padding: 0.5rem 1rem;
  border: 1px solid #333;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #333;
    color: white;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    border: 1px solid #333;
    margin-top: 1rem;
    &:first-child {
      border-bottom: none;
    }
  }
`;

const HighlightButton = styled(NavButton)`
  background-color: #00a76f;
  color: white;
  border: 1px solid #00a76f;
  transition: all 0.3s ease;

  &:hover {
    background-color: #008f5c;
    border-color: #008f5c;
    color: white;
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: ${breakpoints.mobile}) {
    border: none;
    margin-top: 1rem;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
  }
`;

const DropdownContent = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media (max-width: ${breakpoints.mobile}) {
    position: static;
    box-shadow: none;
    width: 100%;
  }
`;

const DropdownLink = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem;
  }
`;

const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.5rem 0.5rem;

  &:hover {
    color: #555;
  }

  &::after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }

  ${({ show }) =>
    show &&
    `
    &::after {
      transform: rotate(-135deg);
    }
  `}
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.mobile}) {
    display: block;
  }
`;

const CloseButton = styled.div`
  display: none;

  @media (max-width: ${breakpoints.mobile}) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
`;

function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavOpen(false);
      }
    };

    if (navOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navOpen]);

  useEffect(() => {
    setDropdownOpen(false); // Close dropdown on route change
    setNavOpen(false); // Close nav on route change
  }, [location]);

  return (
    <NavbarContainer>
      <Logo to="/">Invisio Me</Logo>
      <Hamburger onClick={toggleNav} aria-label="Toggle navigation">
        {navOpen ? <FiX size={24} /> : <FiMenu size={24} />}
      </Hamburger>
      <NavLinks ref={navRef} isOpen={navOpen} aria-hidden={!navOpen}>
        <CloseButton onClick={closeNav} aria-label="Close navigation">
          <FiX size={24} />
        </CloseButton>
        <NavLink to="/" isActive={location.pathname === '/'}>Home</NavLink>
        <NavLink to="/how-it-works" isActive={location.pathname === '/how-it-works'}>How it works</NavLink>
        <NavLink to="/pricing" isActive={location.pathname === '/pricing'}>Pricing</NavLink>
        <Dropdown>
          <DropdownToggle onClick={toggleDropdown} show={dropdownOpen}>
            About us
          </DropdownToggle>
          <DropdownContent show={dropdownOpen}>
            <DropdownLink to="/security">Security</DropdownLink>
            <DropdownLink to="/privacy">Privacy</DropdownLink>
            <DropdownLink to="/about-invisio-me">Invisio Me</DropdownLink>
          </DropdownContent>
        </Dropdown>
        <NavButton to="/login">Log in</NavButton>
        <HighlightButton to="/signup">Sign up</HighlightButton>
      </NavLinks>
    </NavbarContainer>
  );
}

export default Navbar;
