import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <section className="hero">
        <h1>Take Control of Your Online Privacy</h1>
        <p>Invisio helps you remove your personal information from data brokers and safeguard your online privacy.</p>
        <button className="cta-button">How it Works</button>
      </section>
      
      <section id="features" className="features">
        <h2>Features</h2>
        <div className="feature">
          <h3>Comprehensive Data Removal</h3>
          <p>We search and remove your data from dozens of data broker sites.</p>
        </div>
        <div className="feature">
          <h3>Continuous Monitoring</h3>
          <p>Regular checks to ensure your data stays off data broker sites.</p>
        </div>
        <div className="feature">
          <h3>Privacy Reports</h3>
          <p>Receive detailed reports on where your data is found and removed.</p>
        </div>
      </section>
      
      <section id="pricing" className="pricing">
        <h2>Pricing</h2>
        <p>Simple and transparent pricing plans to suit your needs.</p>
        <button className="cta-button">View Plans</button>
      </section>
      
      <section id="about" className="about">
        <h2>About Us</h2>
        <p>Learn more about our mission to protect your online privacy.</p>
      </section>
      
      <section id="contact" className="contact">
        <h2>Contact Us</h2>
        <p>Have questions? Get in touch with us.</p>
      </section>
    </div>
  );
}

export default Home;
