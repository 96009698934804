import React from 'react';

function Pricing() {
  return (
    <div>
      <h1>Pricing</h1>
      <p>Details about pricing plans...</p>
    </div>
  );
}

export default Pricing;
