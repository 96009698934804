import React from 'react';

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
      <p>Manage your account and view request statuses.</p>
    </div>
  );
}

export default Dashboard;
