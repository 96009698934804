import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>Our privacy policy...</p>
    </div>
  );
}

export default PrivacyPolicy;
