import React from 'react';

function Security() {
  return (
    <div>
      <h1>Security</h1>
      <p>Information about security...</p>
    </div>
  );
}

export default Security;
